import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import MyApp from "./components/Main";
import Contact from "./components/Contact";
import Quote from "./components/Quote";
import errorPage from "./components/ErrorPage";


export default function App () {
  return (
    <Router>
      <Navbar />
        <Switch>
          <Route path="/" exact component={MyApp} />
          <Route path="/quote" component={Quote} />
          <Route path="/contact" component={Contact} />
          <Route component={errorPage} />
        </Switch>
      <Footer />
    </Router>
  );
}

