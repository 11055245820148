import React, { Component } from "react";
import { Link } from "react-router-dom";
import M from "materialize-css/dist/js/materialize";

class Navbar extends Component {
  componentDidMount() {
    let sidenav = document.querySelector(".sidenav");
    M.Sidenav.init(sidenav, {});
  }

  render() {
    return (
      <nav className="grey darken-4">
        <div className="nav-wrapper">
          <div className="container">
            <Link to="/" style={{ fontSize: 20 }}>
              Maine Mounting &amp; More
            </Link>
            <ul className="right hide-on-med-and-down">
              <li>
                <Link to="/" style={{ fontSize: 20 }}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/contact" style={{ fontSize: 20 }}>
                  Contact
                </Link>
              </li>
            </ul>

            <ul id="nav-mobile" className="sidenav">
              <li>
                <Link className="sidenav-close" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link className="sidenav-close" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
            <Link to="/" data-target="nav-mobile" className="sidenav-trigger">
              <i className="material-icons">menu</i>
            </Link>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
