import React from "react";

export default function Services() {
  return (
    <div className="center" style={{backgroundColor: "#F5F5F5" }}>
      <div className="container" style={{ padding: "3rem" }}>
      <h3 style={{color: "black", marginTop: 0}}>Services</h3>
      <div className="row">
        <div className="col s12 m4">
          <div className="card">
            <div className="price-header grey lighten-3">
              <p className="package-title">Small TV Wall Mounting</p>
            </div>
            <div className="price-section">
              <div className="price">
                <p>Starting at</p>
                <h4>
                  <span style={{ fontSize: "1.5rem" }}>$</span>60
                </h4>
              </div>
              <div className="card-content">
                <p>**Price does not include hidden wires and wall mount.</p>
              </div>
              <div className="price-footer">
                <a className="btn waves-effect btn-small grey darken-4" href="/#quote">
                  Request a quote
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="col s12 m4">
          <div className="card center">
            <div className="price-header grey lighten-3">
              <p className="package-title">Medium TV Wall Mounting</p>
            </div>
            <div className="price-section">
              <div className="price">
              <p>Starting at</p>
                <h4>
                  <span style={{ fontSize: "1.5rem" }}>$</span>70
                </h4>
              </div>
              <div className="card-content">
                <p>**Price does not include hidden wires and wall mount.</p>
              </div>
              <div className="price-footer">
              <a className="btn waves-effect btn-small grey darken-4" href="/#quote">
                  Request a quote
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="col s12 m4">
          <div className="card">
            <div className="price-header grey lighten-3">
              <p className="package-title">Large TV Wall Mounting</p>
            </div>
            <div className="price-section">
              <div className="price">
              <p>Starting at</p>
                <h4>
                  <span style={{ fontSize: "1.5rem" }}>$</span>90
                </h4>
              </div>
              <div className="card-content">
                <p>**Price does not include hidden wires and wall mount.</p>
              </div>
              <div className="price-footer">
              <a className="btn waves-effect btn-small grey darken-4" href="/#quote">
                  Request a quote
                </a>
              </div>
            </div>
          </div>
        </div>

        
      </div>
      <div style={{marginTop: "2rem"}}>
           <h4>What's Included</h4>
          <table id="product-comparison" className="table-responsive">
          <tbody>
           
              <tr>
                <th>TV unboxing</th>
                <td>
                  <i className="material-icons">check</i>
                </td>
              </tr>
              <tr>
                <th>Mount TV on wall</th>
                <td>
                  <i className="material-icons">check</i>
                </td>
              </tr>
              <tr>
                <th>Secure and Level TV</th>
                <td>
                  <i className="material-icons">check</i>
                </td>
              </tr>
              <tr>
                <th>Connect devices</th>
                <td>
                  <i className="material-icons">check</i>
                </td>
              </tr>
              <tr>
                <th>Basic Remote Programing</th>
                <td>
                  <i className="material-icons">check</i>
                </td>
              </tr>
              <tr>
                <th>Clean up work area</th>
                <td>
                  <i className="material-icons">check</i>
                </td>
              </tr>
              </tbody>
  </table> 
          <div style={{ color: "black"}}>
          <h5>Schedule an appointment with Maine Mounting today! </h5>
          <a href="tel:7726085291" style={{fontSize: "2rem", color: "black"}}>772-608-5291</a>
              </div>
        </div>
        </div>
    </div>
  );
}


