import React from "react";

export default function Contact() {
  return (
    <div
      style={{ marginTop: "3em", marginBottom: "1em" }}
      className="container contactForm"
    >
      <h5 className="center">Contact Us</h5>
      <p className="center"> Have a general question or concern?</p>
      <p className="center" style={{ marginBottom: "2em" }}>
        {" "}
        Please fill out the form below, and we'll get back to you promptly.
      </p>
      <div className="row">
        <form name="messages" method="post" data-netlify="true">
          <div className="row">
            <div className="input-field col s12">
              <input type="hidden" name="form-name" value="messages" />
              <input
                name="name"
                type="text"
                placeholder="Name"
                required
              ></input>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s12">
              <input
                type="tel"
                name="phone_number"
                placeholder="Phone Number"
                required
              ></input>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s12">
              <input
                name="email"
                type="email"
                placeholder="Email"
                className="validate"
                required
              ></input>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s12">
              <textarea
                id="textarea1"
                name="message"
                className="materialize-textarea"
                placeholder="Message"
                required
              ></textarea>
            </div>
          </div>
          <button
            className="btn waves-effect btn-small grey darken-4 right"
            type="submit"
            name="action"
          >
            Submit
            <i className="material-icons right">send</i>
          </button>
          <div className="input-field col s12"><div data-netlify-recaptcha="true"></div></div>
        </form>
      </div>
    </div>
  );
}

