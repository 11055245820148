import React, { Component } from "react";
import M from "materialize-css";
import { Slider, Slide, Caption } from "react-materialize";

class PageBanner extends Component {
  componentDidMount() {
    M.Modal.init(this.Modal);
  }

  render() {
    return (
      <div>
        <Slider
          fullscreen={false}
          options={{
            duration: 0,
            height: 400,
            indicators: false,
            interval: 0,
          }}
        >
          <Slide image={<img alt="" src="images/tv_mount.jpg" />}>
            <Caption placement="center">
              <h1>Maine Mounting & More</h1>
              <a
                href="#quote"
                className="btn yellow darken black-text modal-trigger text-center"
              >
                Get a quote
              </a>
            </Caption>
          </Slide>
        </Slider>
      </div>
    );
  }
}

export default PageBanner;
