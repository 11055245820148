import React from "react";

export default function InfoSection() {
  return (
    <div style={{ backgroundColor: "#f9fafb" }}>
      <div className="container" style={{ padding: "3rem" }}>
        <h3 className="center">Serving the Atlanta Metro Area</h3>
        <h4 className="center" style={{ marginBottom: "6rem" }}>
          TV mounting prices starting at $60 &amp; up!
        </h4>
        <hr style={{ marginBottom: "3rem" }}></hr>
        <div className="row">
          <div className="col s12 m4 center">
            <div className="icon-block">
              <h2 className="dark-text">
                <i className="material-icons" style={{ fontSize: "50px" }}>
                  attach_money
                </i>
              </h2>
              <h5>Affordable Pricing</h5>
              <p className="light center" style={{ fontSize: "20px" }}>
                We make sure that our customers receive top quality work at a
                fair price.
              </p>
            </div>
          </div>

          <div className="col s12 m4 center">
            <div className="icon-block">
              <h2 className="dark-text">
                <i className="material-icons" style={{ fontSize: "50px" }}>
                  date_range
                </i>
              </h2>
              <h5>Available 7 days a week!</h5>
              <p className="light" style={{ fontSize: "20px" }}>
                We are here to provide you service 7 days a week.
              </p>
            </div>
          </div>

          <div className="col s12 m4 center">
            <div className="icon-block">
              <h2 className="dark-text">
                <i className="material-icons" style={{ fontSize: "50px" }}>
                  sentiment_very_satisfied
                </i>
              </h2>
              <h5>Satisfaction Guarantee</h5>
              <p className="light" style={{ fontSize: "20px" }}>
                We stand behind the quality of our work. If you are not
                satisfied with the job done, we will gladly fix the issue at no
                additional cost.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
