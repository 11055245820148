import React from "react";
import PageBanner from "./PageBanner";
import Gallery from "./Gallery";
import Services from "./Services"
import InfoSection from "./InfoSection";
import Quote from "./Quote";

export default function MyApp () {
    return (
      <div>
        <PageBanner />
        <InfoSection />
        <Gallery />
        <Services />
        <Quote />
      </div>
    );
}

