import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="page-footer grey darken-3">
      <div className="container grey-text text-lighten-2">
        <div className="row">
          <div className="footerLogo col l4 s12 center">
            <Link className="grey-text text-lighten-2" to="/">
              <h5>Maine Mounting &amp; More</h5>
            </Link>
          </div>

          <div className="connect col l4 s12 center">
            <h5>Stay Connected</h5>
            <ul>
              {/* <li>
                <Link className="grey-text text-darken-2" to="#">
                  <i className="fab fa-facebook fa-2x"></i>
                </Link>
              </li> */}
              <li style={{ marginLeft: "1rem" }}>
                <a
                  className="grey-text text-lighten-2"
                  href="https://www.instagram.com/mainemounting/"
                  target="noopener noreferrer"
                >
                  <i className="fab fa-instagram fa-2x"></i>
                </a>
              </li>
            </ul>
          </div>

          <div className="location col l4 s12 center grey-text text-lighten-2">
            <h5>Call Today!</h5>
            <ul>
              <li style={{ fontSize: "18px" }}>
                Serving the Atlanta Metro Area
              </li>
              <li>
                <a href="tel:7726085291" className="grey-text text-lighten-2">
                  {" "}
                  772-608-5291
                </a>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-copyright center">
        <div className="container grey-text text-lighten-2">
          © Copyright 2021,{" "}
          <Link className="grey-text text-lighten-2" to="/">
            Maine Mounting & More LLC - All Rights Reserved.
          </Link>
        </div>
      </div>
    </footer>
  );
}
