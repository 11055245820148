import React from "react";
import { Link } from "react-router-dom";

export default function errorPage() {
  return (
    <div className="center" style={{marginBottom: "4em"}}>
      <h2>404 - Page not found.</h2>
      <Link style={{color: "black", fontSize: "20px"}} to="/">Go home</Link>
    </div>
  );
}
