import React from "react";

export default function Quote() {
  return (
    <div style={{ marginBottom: "5rem", marginTop: "5rem" }}>
      <div className="container">
        <h5 id="quote" className="center">
          Need a Quote?
        </h5>
        <p className="center">Fill out the fields below.</p>
        <p className="center" style={{ marginBottom: "2em" }}>
          Required fields *
        </p>
        <div className="row">
          <form id="quoteForm" name="quotes" method="post" data-netlify="true">
            <input type="hidden" name="form-name" value="quotes" />
            <div className="row">
              <div className="input-field col s12">
                <input
                  name="name"
                  type="text"
                  placeholder="Name*"
                  required
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s12">
                <input
                  name="tv_size"
                  type="text"
                  required
                  placeholder="TV Size*"
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s12">
                <textarea
                  id="textarea1"
                  name="special_requirements"
                  className="materialize-textarea"
                  placeholder="Special Requirements? (optional)"
                ></textarea>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s12">
                <input
                  type="tel"
                  name="phone_number"
                  placeholder="Phone Number*"
                  required
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s12">
                <input
                  name="email"
                  type="email"
                  placeholder="Email*"
                  className="validate"
                  required
                ></input>
              </div>
            </div>
            <button
              className="btn waves-effect btn-small grey darken-4 right"
              type="submit"
              name="action"
            >
              Get a Quote
              <i className="material-icons right">send</i>
            </button>
            <div className="input-field col s12"><div data-netlify-recaptcha="true"></div></div>
          </form> 
        </div>
      </div>
    </div>
  );
}

