import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
  {
    original: "images/main_mount1.jpg",
    thumbnail: "images/main_mount1.jpg",
  },
  {
    original: "images/main_mount2.jpg",
    thumbnail: "images/main_mount2.jpg",
  },
  {
    original: "images/main_mount3.jpg",
    thumbnail: "images/main_mount3.jpg",
  },
  {
    original: "images/main_mount4.jpg",
    thumbnail: "images/main_mount4.jpg",
  },
  {
    original: "images/main_mount5.jpg",
    thumbnail: "images/main_mount5.jpg",
  },
  {
    original: "images/main_mount6.jpg",
    thumbnail: "images/main_mount6.jpg",
  },
  {
    original: "images/main_mount7.jpg",
    thumbnail: "images/main_mount7.jpg",
  },
];

export default function Gallery() {
  return (
    <div style={{ backgroundColor: "#404040" }}>
      <div className="container center-align" style={{ padding: "2rem" }}>
        <h3 style={{ marginBottom: "4rem", marginTop: 0, color: "white" }}>
          TV Mounting Service Gallery
        </h3>
        <ImageGallery showBullets={true} items={images} />
      </div>
    </div>
  );
}

